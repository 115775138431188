// import { http, param } from "../utils/http";
import axios from "axios";
const endPointUrl =
  "https://i2r7z4qtt5.execute-api.us-east-2.amazonaws.com/dev";
// https://22a1wmvv9c.execute-api.ap-south-1.amazonaws.com/dev
const userToken = localStorage.getItem("userIdToken");
const recallServices = {
  getAllrecalls(query_params) {
    return axios
      .get(
        `${endPointUrl}/getrecalls`,
        {
          params: query_params,
        },
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        console.log("hello its err in service", err);
      });
  },
  exportAllRecalls(query_params) {
    return axios
      .get(
        `${endPointUrl}/exportRecalls`,
        {
          params: query_params,
        },
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        console.log("hello its err in service", err);
      });
  },
  getRecall(objectId) {
    return axios
      .get(`${endPointUrl}/getrecall?objectId=${objectId}`, {
        headers: {
          Authorization: `${userToken}`,
        },
      })
      .then((resp) => {
        return resp.data;
      });
  },
  getMeta() {
    return axios.get(`${endPointUrl}/getmeta`).then((resp) => {
      return resp.data;
    });
  },
};
export default recallServices;
