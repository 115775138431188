import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import recallServices from "../../../../services/recall";
import { columnDefs } from "./GridData";
import ViewRecall from "./ViewRecall.vue";
import ExcelJS from "exceljs";

export default {
  name: "App",
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      per_page: [10, 50, 100],
      perPage: 50,
      currentPage: 1,
      total: 0,
      totalPages: 0,
      defaultColDef: null,
      globalSearchText: "",
      headerSerachUrl: "",
      sortingUrl: "",
      sortingOrder: null,
      showRecallData: null,
      gridOptions: null,
      lastUpdated: "",
      rowModelType: null,
    };
  },
  components: {
    AgGridVue,
    ViewRecall,
  },
  created() {
    this.rowModelType = "serverSide";
    this.getMetaData();
  },

  beforeMount() {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      enableValue: true,
      enableRowGroup: false,
      enablePivot: true,
      sortable: true,
      resizable: true,
      filter: true,
      editable: true,
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
      menuTabs: ["generalMenuTab", "filterMenuTab", "columnsMenuTab"],
    };
    this.columnDefs = columnDefs;
    this.gridOptions = {
      rowModelType: "serverSide",
      suppressDragLeaveHidesColumns: true,
      enableCellChangeFlash: false,
      suppressRowTransform: true,
      suppressRowClickSelection: true,
      suppressRowDeselection: true,
      maxConcurrentDatasourceRequests: 2,
      maxBlocksInCache: 10,
      headerHeight: 40,
      rowHeight: 32,
      enableRangeSelection: true,
      sortingOrder: ["desc", "asc", null],
      enableBrowserTooltips: true,
      onSortChanged: this.onSortChanged,
      overlayNoRowsTemplate:
        '<span style="padding: 10px;">No records found</span>',
      getContextMenuItems: this.getContextMenuItems,
    };
  },
  watch: {
    currentPage(val) {
      this.onGridReady({ api: this.gridApi, columnApi: this.columnApi });
    },
  },
  methods: {
    getContextMenuItems(params) {
      let that = this;
      const defaultItems = params.defaultItems;

      const filteredItems = defaultItems.filter((item) => item !== "export");

      // Create a custom menu item
      const customMenuItem = {
        name: "Excel Export",
        action: async function () {
          const gridColState = JSON.parse(
            localStorage.getItem("agGridColumState")
          );
          const gridFilterModel = JSON.parse(
            localStorage.getItem("agGridFilterModel")
          );

          const sort = gridColState.filter((s) => s.sort !== null);

          const email = localStorage.getItem("userId");

          const query_param = {
            search_query: that.globalSearchText,
            sort_field: sort.length !== 0 ? sort[0].colId : null,
            sort_direction: sort.length !== 0 ? sort[0].sort : null,
            filter_model: gridFilterModel,
            email: email,
          };

          const resp = await recallServices.exportAllRecalls(query_param);

          console.log(resp);

          that._showConfirm(resp.body, {
            variant: "success",
            title: "Message",
            buttonTitle: "Ok",
          });
        },
        icon: '<span class="ag-icon ag-icon-excel"></span>',
      };

      // Combine the custom menu item with the default items
      const allItems = [...filteredItems, customMenuItem];

      return allItems;
    },

    debounceInput: _.debounce(function () {
      this.currentPage = 1;
      const newText = this.globalSearchText;
      if (newText.length > 2) {
        this.fetchData({ api: this.gridApi, columnApi: this.columnApi });
      } else if (newText.length === 0) {
        this.fetchData({ api: this.gridApi, columnApi: this.columnApi });
      }
    }, 500),

    getMetaData() {
      recallServices.getMeta().then((resp) => {
        if (resp && resp !== undefined) {
          this.lastUpdated = `Last Updated: ${moment(resp.data.completed_date)
            .local()
            .format("MM/DD/YY")}`;
        } else {
          this.lastUpdated = "";
        }
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      const gridColState = JSON.parse(localStorage.getItem("agGridColumState"));
      const gridFilterModel = JSON.parse(
        localStorage.getItem("agGridFilterModel")
      );
      if (gridColState && gridColState.length > 0) {
        this.columnApi.applyColumnState({
          state: gridColState,
          applyOrder: true,
        });
      }
      if (gridFilterModel) {
        params.api.setFilterModel(gridFilterModel);
      }

      this.fetchData(params);
    },
    fetchData(params) {
      let that = this;
      that.gridApi = params.api;
      that.columnApi = params.columnApi;
      localStorage.setItem(
        "agGridColumState",
        JSON.stringify(params.columnApi.getColumnState())
      );
      localStorage.setItem(
        "agGridFilterModel",
        JSON.stringify(params.api.getFilterModel())
      );
      const datasource = {
        getRows(params) {
          const query_param = {
            search_query: that.globalSearchText,
            page_number: that.currentPage,
            page_size: that.perPage,
            sort_field:
              params.request.sortModel.length !== 0
                ? params.request.sortModel[0].colId
                : null,
            sort_direction:
              params.request.sortModel.length !== 0
                ? params.request.sortModel[0].sort
                : null,
            filter_model: params.request.filterModel,
          };

          recallServices.getAllrecalls(query_param).then((resp) => {
            if (resp && resp !== undefined) {
              params.successCallback(resp.data);
              that.total = resp.totalRecords;
              if (that.total == 0) {
                that.gridApi.showNoRowsOverlay();
              } else {
                that.gridApi.hideOverlay();
              }
            } else {
              params.failCallback();
              that.total = 0;
            }
          });
        },
      };

      params.api.setServerSideDatasource(datasource);
    },
    onRowClicked(prm) {
      if (prm.colDef && prm.colDef.field === 'recall_link') {
        prm.event.preventDefault();
      } else {
        this.showRecallData = prm.data;
        this.$refs["my-modal"].show();
        recallServices.getRecall(this.showRecallData._id).then((resp) => {
          this.showRecallData = resp.data;
        });
      }
      
    },
    selectPerPage(perPage) {
      this.perPage = perPage;
      this.onGridReady({ api: this.gridApi, columnApi: this.columnApi });
    },

    onFilterChanged(event) {
      localStorage.setItem(
        "agGridFilterModel",
        JSON.stringify(event.api.getFilterModel())
      );
      this.fetchData(event);
    },

    onColumnMoved(event) {
      localStorage.setItem(
        "agGridColumState",
        JSON.stringify(event.columnApi.getColumnState())
      );
    },
    visibleChanged(event) {
      localStorage.setItem(
        "agGridColumState",
        JSON.stringify(event.columnApi.getColumnState())
      );
    },
    onSortChanged(event) {
      localStorage.setItem(
        "agGridColumState",
        JSON.stringify(event.columnApi.getColumnState())
      );
      this.fetchData(event);
    },
  },
};
