export function DateComparator(filterLocalDateAtMidnight, cellValue) {
  var dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }
  // In the example application, dates are stored as dd/mm/yyyy
  // We create a Date object for comparison against the filter date
  var dateSplit = dateAsString.split("T");
  var dateParts = dateSplit[0].split("-");
  var day = Number(dateParts[2]);
  var month = Number(dateParts[1]) - 1;
  var year = Number(dateParts[0]);
  var cellDate = new Date(year, month, day);

  // Now that both parameters are Date objects, we can compare
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
}
export const product_code = [
  "",
  "DJR",
  "QLR",
  "HCC",
  "JOL",
  "LIE",
  "JID",
  "MNI",
  "LMH",
  "GET",
  "GDW",
  "OUY",
  "CBP",
  "NGE",
  "MNO",
  "LNN",
  "MNT",
  "LKN",
  "MSW",
  "HPZ",
  "NIQ",
  "PPM",
  "KEY",
  "PFX",
  "NDA",
  "NAO",
  "IRT",
  "MZO",
  "HTR",
  "KGO",
  "MCF",
  "DQT",
  "DWH",
  "DRT",
  "CCX",
  "LGC",
  "CEW",
  "KPF--",
  "DS-21",
  "EXEMP",
  "NQQ",
  "GAB",
  "HIG",
  "HGB",
  "PCD",
  "MEA",
  "LKC",
  "MMO",
  "RER",
  "PDT",
  "FMQ",
  "FJK",
  "NVQ",
  "GDF",
  "HTE",
  "LYA",
  "HAO",
  "PQA",
  "NVM",
  "JIA",
  "MCN",
  "CJW",
  "FXY",
  "LWS",
  "PLV",
  "OWN",
  "OTM",
  "JTO",
  "IDO",
  "FRI",
  "NHT",
  "INO",
  "KPG",
  "NVK",
  "DXE",
  "OZP",
  "DML",
  "JCF",
  "OJF",
  "MST",
  "MCZ",
  "OYP",
  "RFK",
  "KLS",
  "DOA",
  "EJI",
  "JZW",
  "JGQ",
  "JSG",
  "NNI",
  "INP",
  "JPA",
  "PPB",
  "ITQ",
  "FWX",
  "KWH",
  "OFY",
  "GAG",
  "GNB",
  "GGN",
  "LNY",
  "JFJ",
  "QIH",
  "JKS",
  "LOM--",
  "KOI",
  "FOX",
  "GEY",
  "NAV",
  "KLI",
  "OWF",
  "MXE",
  "LON",
  "KLC",
  "LCQ",
  "HYJ",
  "KPR",
  "NRU",
  "DFT",
  "HFS",
  "PUI",
  "FFK--",
  "CGX",
  "LOJ",
  "JSJ",
  "IKY",
  "FZS",
  "OMB",
  "PER",
  "EJK",
  "frn",
  "LLI",
  "KMW",
  "KOC",
  "LOZ",
  "PKC",
  "HFE",
  "OAY",
  "IKD",
  "IME",
  "KGB",
  "CJE",
  "OBI",
  "EZB",
  "HIF",
  "HRM",
  "HHK",
  "DXJ",
  "lkv",
  "MIJ",
  "FPB",
  "NLU",
  "OAT",
  "JPB",
  "LFL",
  "LDM",
  "KDQ",
  "MRN",
  "MJA",
  "NQK",
  "MMW",
  "FDF",
  "HEB",
  "GDY",
  "LCX",
  "JTY",
  "GFJ",
  "REN",
  "CCW",
  "FEQ",
  "KOY",
  "HQL",
  "QAI",
  "HSN",
  "NWB",
  "NZW",
  "LOK",
  "BZK",
  "NSQ",
  "dxg",
  "LOD",
  "JHR",
  "MEB",
  "PVX",
  "GAS",
  "NRP",
  "RCC",
  "FYD",
  "ITJ",
  "DPZ",
  "FTY",
  "IXW",
  "CAH",
  "LPC",
  "PND",
  "ORO",
  "GRP",
  "FEZ",
  "MQF",
  "CGN--",
  "JMG",
  "OPP",
  "D",
  "RIB",
  "EKN",
  "JYO",
  "OEZ",
  "KYJ",
  "JIT",
  "NEU",
  "PHP",
  "GZO",
  "PJI",
  "GIM",
  "LJY",
  "MKZ",
  "GMZ",
  "MQM",
  "OIE",
  "OYD",
  "BWC",
  "LFR",
  "KPQ",
  "DZi",
  "KMK",
  "HRH",
  "FMI",
  "MNL",
  "CCK",
  "KDL",
  "JOW",
  "HBB",
  "gkz",
  "NFJ",
  "NTM",
  "EAX",
  "HZT",
  "---",
  "IZX",
  "GGM",
  "IBG",
  "LXL",
  "OOM",
  "KWQ",
  "MLY",
  "LXV",
  "HCG",
  "EKM",
  "GXE",
  "HTZ",
  "PNN",
  "NGZ",
  "OCC",
  "OXO",
  "IXR",
  "HRR",
  "OWI",
  "PWC",
  "LS-99",
  "OHA",
  "MRR",
  "LIG",
  "LTD",
  "GKR",
  "ODX",
  "GAM",
  "DQY",
  "KOJ",
  "HTC",
  "HSB",
  "PEX",
  "LJT",
  "MJX",
  "FDS",
  "HPP",
  "LGX",
  "GCA",
  "JZO",
  "DAK",
  "NDJ",
  "FZP",
  "KRP",
  "LGD",
  "LAZ",
  "HKO",
  "MKJ--",
  "GTY",
  "NIP",
  "QKO",
  "JXE",
  "KMJ",
  "KXG",
  "FMP",
  "GKG",
  "DRL",
  "DXH",
  "MND",
  "MCB",
  "LQK",
  "IZI",
  "LOM",
  "KWZ",
  "FPO",
  "MMY",
  "EAZ",
  "LNX",
  "HWE",
  "CDQ",
  "FEI",
  "CKB",
  "MPU",
  "KYT",
  "MGZ",
  "DZA",
  "U28",
  "LDS",
  "GWM",
  "RHF",
  "BTO",
  "OCS",
  "BTI",
  "MCM",
  "MWS",
  "KLE",
  "NZD",
  "PAO",
  "HYF",
  "OLV",
  "KPJ",
  "LMF",
  "OZZ",
  "MFD",
  "LWG",
  "PFQ",
  "JIN",
  "IZL--",
  "PSP",
  "HXK",
  "KXN",
  "L--22",
  "PJQ",
  "HDD",
  "LGM",
  "HQD",
  "QMC",
  "DJR--",
  "HOY",
  "GQG",
  "JDK",
  "GCT",
  "FRG",
  "GBS",
  "FNJ",
  "DSJ",
  "HLI",
  "HXQ",
  "CEC",
  "JLB",
  "DXC",
  "GBX",
  "IPS",
  "MXP",
  "JAJ",
  "GAQ",
  "IMB",
  "PWE",
  "LSK",
  "LSL",
  "MAV",
  "JJQ",
  "LEZ",
  "NPV",
  "NNY",
  "QMN",
  "LJG",
  "ILS",
  "JBL",
  "JXG",
  "ONW",
  "GBL",
  "JEC",
  "GKI",
  "MTL",
  "FRZ",
  "MNR",
  "JJF",
  "IQK",
  "NRS",
  "LPQ",
  "KXK",
  "FPD",
  "PYI",
  "LZK",
  "GCW",
  "ONG",
  "LHN",
  "MJH",
  "PDQ",
  "MEF",
  "HIR",
  "NFA",
  "OEA",
  "MRG",
  "HNQ",
  "FIE",
  "HQP",
  "KGS",
  "PPR",
  "JXN",
  "HJO",
  "PKF",
  "IOR",
  "EEF",
  "MFC",
  "OOC",
  "IPR",
  "OZO",
  "HDB",
  "MYF",
  "KME",
  "NUA",
  "FTC",
  "CBK",
  "HQE",
  "JTS",
  "DXQ",
  "LDR",
  "OKE",
  "NGJ",
  "HGC",
  "MRA",
  "EKZ",
  "PIF",
  "HWW",
  "MED",
  "OGO",
  "FOS",
  "OZD",
  "HTD",
  "DWB",
  "KEW",
  "NKM",
  "QLN",
  "LNZ",
  "NVZ",
  "NXS",
  "DLZ",
  "DXO",
  "NCR",
  "HNR",
  "MQX",
  "KHQ",
  "FOZ",
  "CGN",
  "FKP",
  "REP",
  "FMK",
  "BZC",
  "CZP",
  "NDC",
  "CBZ",
  "JQC",
  "HDC",
  "CGS",
  "KRG",
  "CXT",
  "FAE",
  "GJH",
  "MDB",
  "LFQO",
  "MBV",
  "FQO",
  "CHL",
  "MBH",
  "LNW",
  "DPS",
  "LZY",
  "GCI",
  "KRY",
  "LSW",
  "DWO",
  "EMO",
  "OZT",
  "KHJ",
  "ILI",
  "LEH",
  "lZE",
  "LGW",
  "EMI",
  "MLR",
  "OLO",
  "MUJ",
  "HRY",
  "EOQ",
  "MAL",
  "KOH",
  "FKR",
  "LAO",
  "BYF",
  "MZW",
  "LKV",
  "GTJ",
  "JWX",
  "BZD",
  "OCT",
  "KOB",
  "LIZ",
  "INK",
  "BZT",
  "PFJ",
  "DTJ",
  "MPO",
  "KQZ",
  "PRI",
  "JFC",
  "HWQ",
  "MLM",
  "FMZ",
  "MUI",
  "NPZ",
  "JZJ",
  "HXS",
  "MNS",
  "GGK",
  "FZW",
  "DPW",
  "NCW",
  "OCY",
  "QBJ",
  "KIT",
  "JSH",
  "BYI",
  "ONB",
  "LJM",
  "CIJ",
  "OZN",
  "KOX",
  "IZW",
  "L",
  "GDR",
  "CIT",
  "FYE",
  "HTY",
  "MBQ",
  "REM",
  "PAV",
  "DIF",
  "XLH",
  "HFW",
  "NWU",
  "RET",
  "JRC",
  "MNQ",
  "EXY",
  "JXK",
  "CHI",
  "OBH",
  "DXT",
  "ESD",
  "CBT",
  "NUK",
  "EZL",
  "OKV",
  "rad",
  "NEZ",
  "EKR",
  "NDS",
  "OIR",
  "EZX",
  "OIB",
  "NLI",
  "IDZ",
  "MTF",
  "FKH",
  "DIH",
  "JJJ",
  "MTX",
  "GFO",
  "KNO",
  "FLL",
  "OHQ",
  "NJE",
  "HEW",
  "MKQ",
  "DMT",
  "KYI",
  "DCK",
  "MIH",
  "JBP",
  "LFG",
  "HFN",
  "MFK",
  "OAD",
  "KJJ",
  "PXC",
  "JJT",
  "JQX",
  "DZL",
  "KNX",
  "DYB",
  "DDG",
  "ZZZ",
  "MDR",
  "FZX",
  "OQG",
  "PII",
  "GZF",
  "QEP",
  "LXC",
  "MCY",
  "EMA",
  "FPK",
  "NPR",
  "GEJ",
  "NKU",
  "74",
  "KNC",
  "KKY",
  "FBQ",
  "OHH",
  "I--TI",
  "JKR",
  "OPR",
  "KTO",
  "JJH",
  "EWL",
  "IYE",
  "LRO",
  "JNL",
  "EFQ",
  "CFT",
  "JTQ",
  "FGY",
  "JNO",
  "OVN",
  "LLD",
  "EFT",
  "KFX",
  "BTE",
  "BTS",
  "MZP",
  "EZC",
  "NDP",
  "GKL",
  "OIJ",
  "LYS",
  "KRO",
  "FET",
  "ECX",
  "PMW",
  "NPD",
  "GXD",
  "LZF",
  "MXA",
  "ILG",
  "KPN",
  "BSN",
  "GJT",
  "GHQ",
  "GGP",
  "FZY",
  "EQN",
  "EHY",
  "LKB",
  "JLX",
  "HQF",
  "CDL",
  "EXX",
  "FSO",
  "LEJ",
  "FPY",
  "MJN",
  "CJO",
  "MPR",
  "KPA",
  "NKB",
  "MNH",
  "DWC",
  "HBF",
  "LWE",
  "MIP",
  "REX",
  "PGE",
  "LTW",
  "FDE",
  "LLK",
  "PBX",
  "HBO",
  "MDI",
  "DNN",
  "DYE",
  "FRN--",
  "NOC",
  "JAY",
  "BSF",
  "OGE",
  "OTD",
  "OXQ",
  "REQ",
  "LHC",
  "LSO",
  "EQJ",
  "DKC",
  "EPP",
  "GZC",
  "KAL",
  "KRL",
  "FXM",
  "000",
  "FAM",
  "OWG",
  "IWE",
  "LTK",
  "KNS",
  "CGC",
  "NQP",
  "HQN",
  "DS-22",
  "LCD",
  "JHM",
  "PSY",
  "GBA",
  "NGV",
  "KZH",
  "CDD",
  "LKA",
  "CBL",
  "CEE",
  "DWJ",
  "QLW",
  "IRQ",
  "IRP",
  "IYO",
  "PAH",
  "CDT",
  "DQE",
  "EFW",
  "NUU",
  "IMS",
  "LXB",
  "MSB",
  "KEO",
  "MGA",
  "QJQ",
  "OGD",
  "FFF",
  "FRO",
  "OGK",
  "EGG",
  "HHW",
  "FNL",
  "PDH",
  "NPN",
  "KGG",
  "MGP",
  "GEH",
  "EFA",
  "MUH",
  "OOE",
  "FII",
  "MHJ",
  "NDL",
  "EMG",
  "RCS",
  "EIH",
  "PLO",
  "LTF",
  "GJA",
  "FSA",
  "GBZ",
  "NAJ",
  "CGJ",
  "LSZ",
  "KCP",
  "JDO",
  "MRS",
  "ndr",
  "LLC",
  "GFA",
  "BTL",
  "NLF",
  "NSX",
  "EYB",
  "EBI",
  "OSF",
  "EMF",
  "FWP",
  "GJS",
  "BYG",
  "LBS",
  "NVP",
  "CIQ",
  "EFB",
  "PMN",
  "PTI",
  "cbk",
  "LFY",
  "BSR",
  "HWJ",
  "FRN",
  "LYJ",
  "LHX",
  "LQC",
  "KWI",
  "FKB",
  "KNH",
  "MLZ",
  "CIZ",
  "GDC",
  "IPM",
  "HBL",
  "NAW",
  "JJC",
  "DIC",
  "NIG",
  "KMI",
  "LOQ",
  "OJH",
  "N/A",
  "JQW",
  "JST",
  "DWQ",
  "IMG",
  "LJS",
  "EHZ",
  "OJZ",
  "MJQ",
  "OTE",
  "PHL",
  "NYQ",
  "JSP",
  "NDR",
  "NOF",
  "BXB",
  "FMT",
  "JDI",
  "NQB",
  "KQM",
  "OCK",
  "MBB",
  "79F",
  "KBW",
  "NVI",
  "BYZ",
  "CIG",
  "NED",
  "IZZ",
  "LQJ",
  "FEG",
  "JJX",
  "LRY",
  "HNL",
  "EDH",
  "MHI",
  "KAC",
  "LDJ",
  "EGS",
  "HWC",
  "LDY",
  "JFH",
  "KDP",
  "IYX",
  "NEK",
  "DCN",
  "LXM",
  "OFZ",
  "cck",
  "EJF",
  "GMD",
  "DTZ",
  "MOS",
  "LRR",
  "JHX",
  "NLH",
  "KDG",
  "RGB",
  "RBS",
  "EQH",
  "QJZ",
  "NFG",
  "LKJ",
  "LZJ",
  "MMD",
  "LCJ",
  "DEW",
  "GSO",
  "GEI",
  "JDG",
  "GKA",
  "EHJ",
  "LAF",
  "DRM",
  "FBN",
  "ETY",
  "NQE",
  "IQO",
  "PRV",
  "EZQ",
  "NQN",
  "DSB",
  "FEB",
  "OGR",
  "NTW",
  "NNW",
  "PGN",
  "ITI",
  "PCH",
  "LZP",
  "MQP",
  "MJC",
  "KTW",
  "MEQ",
  "IQA",
  "EKC",
  "MDV",
  "ETN",
  "PAM",
  "DSS",
  "PDJ",
  "ISP",
  "CBR",
  "GSK",
  "GKB",
  "FWM",
  "ECQ",
  "HXO",
  "JCX",
  "CKA",
  "HXB",
  "IYN",
  "PRH",
  "JXB",
  "LYU",
  "IMD",
  "DLJ",
  "OUO",
  "IZO",
  "GIZ",
  "NA",
  "MLM--",
  "LMA",
  "KBN",
  "CIC",
  "MIE",
  "GXI",
  "DEH",
  "LIA",
  "OYA",
  "IQZ",
  "KIJ",
  "FAF",
  "FGB",
  "PCL",
  "LYT",
  "80FMZ",
  "MRC",
  "DTK",
  "QOS",
  "PHI",
  "FCM",
  "FIR",
  "FED",
  "HTQ",
  "rce",
  "DZJ",
  "MJJ",
  "NGY",
  "BSB",
  "MAY",
  "FGO",
  "OHP",
  "IEO",
  "MTV",
  "OGZ",
  "GBB",
  "HBT",
  "CGL",
  "LPB",
  "DTI",
  "OBC",
  "KFM",
  "HXD",
  "LCI",
  "ONO",
  "LKP",
  "IMJ",
  "EAJ",
  "FRC",
  "KWL",
  "MPN",
  "NEY",
  "OPL",
  "NXT",
  "MIU",
  "OPT",
  "ONU",
  "JQW--",
  "LYZ",
  "DBA",
  "LQP",
  "KYX",
  "LLZ",
  "HMX",
  "LTI",
  "RFN",
  "DBM",
  "LIT",
  "MEH",
  "LNR",
  "IMI",
  "IPF",
  "KWS",
  "EHD",
  "PMU",
  "MKW",
  "MDU",
  "GEA",
  "JDR",
  "EZY",
  "OAP",
  "NTE",
  "FTD",
  "BTT",
  "KRD",
  "OUT",
  "HWB",
  "PCU",
  "DHB",
  "MWH",
  "LXT",
  "JAD",
  "JSO",
  "JET",
  "NSP",
  "CDS",
  "NVE",
  "GDG",
  "OGH",
  "PDZ",
  "NEO",
  "KDN",
  "PWI",
  "GHR",
  "MAF",
  "FEO",
  "PJC",
  "HXC",
  "BZQ",
  "JJM",
  "DAD",
  "PNG",
  "LIB",
  "FFA",
  "max",
  "CHH",
  "KQO",
  "KKK",
  "NDY",
  "LMB",
  "MEC",
  "JOK",
  "NWJ",
  "PRL",
  "HME",
  "NOE",
  "GZX",
  "LEA",
  "IXQ",
  "JAF",
  "QEA",
  "LJH",
  "HDF",
  "KLT",
  "FLF",
  "JIS",
  "HIS",
  "EJG",
  "FKQ",
  "DRA",
  "FTL",
  "QFG",
  "PQE",
  "PSV",
  "CAT",
  "MBL",
  "POA",
  "FAP",
  "IWF",
  "KWP",
  "FTA",
  "JTC",
  "LXH",
  "OVB",
  "LFW",
  "JDC",
  "IZQ",
  "KRZ",
  "JKC",
  "ORA",
  "JFM",
  "REG",
  "NDZ",
  "GWD",
  "LSI",
  "CAD",
  "NOP",
  "POD",
  "NVJ",
  "LTZ",
  "KGI",
  "GIR",
  "OUF",
  "GCX",
  "NJM",
  "LDQ",
  "PYC",
  "ODE",
  "GXN",
  "JLW",
  "KNT",
  "HKS",
  "EIC",
  "NIO",
  "GWN",
  "NKF",
  "INX",
  "LJW",
  "MMK",
  "HHM",
  "NTV",
  "JHW",
  "MXZ",
  "NDE",
  "DXN",
  "CEP",
  "JBF",
  "CAN",
  "MEW",
  "HQG",
  "KIH",
  "LFQ",
  "CAJ",
  "OJV",
  "KWR",
  "KKS",
  "EGJ",
  "OUH",
  "FQM",
  "MSS",
  "PEK",
  "EBA",
  "JZL",
  "PRC",
  "MWM",
  "OOD",
  "JLG",
  "KMF",
  "DHX",
  "GAE",
  "LJZ",
  "GWQ",
  "MAC",
  "JIQ",
  "HQB",
  "KNK",
  "MQR",
  "EIA",
  "FGX",
  "FMW",
  "PHC",
  "KCZ",
  "FRW",
  "FPS",
  "BSK",
  "MLA",
  "DQA",
  "NKE",
  "IZD",
  "OLR",
  "DRY",
  "GRO",
  "OHY",
  "BRX",
  "CDW",
  "BTM",
  "IWY",
  "QRY",
  "GFD",
  "KGZ",
  "FMF",
  "CBN",
  "L22",
  "FCN",
  "KCM",
  "LMD",
  "MXC",
  "MJP",
  "MPW",
  "HQX",
  "MDP",
  "NPE",
  "EBZ",
  "FBR",
  "LOR",
  "LHQ",
  "HIB",
  "HBE",
  "FFS",
  "KRA",
  "GAH",
  "JOJ",
  "OYJ",
  "NVR",
  "GBW",
  "NLM",
  "IPL",
  "ILX",
  "HKN",
  "LTJ",
  "LNO",
  "ILO",
  "NCX",
  "KZD",
  "HBC",
  "LZS",
  "LOX",
  "ISA",
  "KYF",
  "HST",
  "MJK",
  "JSD",
  "GAC",
  "OYG",
  "PAA",
  "NPT",
  "ITF",
  "EQL",
  "OQI",
  "MQJ",
  "KTT",
  "LFJ",
  "MHW",
  "LKM",
  "EZO",
  "LZA",
  "LDT",
  "OHG",
  "ODG",
  "MKJ",
  "GDS",
  "ETB",
  "MQK",
  "NBC",
  "KOE",
  "HIO",
  "CDM",
  "LOL",
  "HGX",
  "HJB",
  "GCB--",
  "PIX",
  "DTA",
  "GAO",
  "JLJ",
  "LZC",
  "cac",
  "FSH",
  "BTR",
  "CDZ",
  "FXX",
  "HXL",
  "6885",
  "JDL",
  "DRE",
  "EBF",
  "GOD",
  "KFF",
  "NGX",
  "DBF",
  "NRA",
  "GWL",
  "FWE",
  "FAD",
  "LID",
  "NEW",
  "PHU",
  "OVQ",
  "MBI",
  "NME",
  "HGL",
  "OMP",
  "BRY",
  "EDI",
  "ELR",
  "OLI",
  "GDX",
  "LNS",
  "KMM",
  "OMA",
  "OTO",
  "OIS",
  "MNV",
  "JTT",
  "JAC",
  "MDS",
  "JDP",
  "FYA",
  "OFC",
  "DYH",
  "QKP",
  "OFL",
  "NSB",
  "DYW",
  "PIU",
  "JJW",
  "LEG",
  "DTH",
  "HGM",
  "KXA",
  "LBI",
  "DRC",
  "LJE",
  "DSA",
  "JGP",
  "MXD",
  "GCC",
  "DYJ",
  "FZO",
  "CAF",
  "NQM",
  "PPN",
  "NIN",
  "MVU",
  "KHS",
  "NYD",
  "MEG",
  "HXW",
  "RFC",
  "GAP",
  "LQL",
  "INI",
  "KHY",
  "MRU",
  "MUE",
  "GTZ",
  "NJR",
  "MCX",
  "EKH",
  "OUE",
  "DRF",
  "NIM",
  "PDU",
  "BTY",
  "KYB",
  "INQ",
  "OAH",
  "KPE",
  "GXH",
  "ETA",
  "LLG",
  "ND",
  "NTO",
  "POQ",
  "NJT",
  "MQC",
  "IOL",
  "OLP",
  "DGK",
  "NNZ",
  "PKE",
  "KPO",
  "MQE",
  "DIS",
  "DWL",
  "IDP",
  "CGI",
  "KCO",
  "DHR",
  "OHR",
  "JXI",
  "QEY",
  "NWY",
  "NHC",
  "IZL",
  "LRQ",
  "LQF",
  "DEM",
  "RCR",
  "JEQ",
  "BTG",
  "RCE",
  "JFP",
  "CCT",
  "PPG",
  "LNM",
  "MOQ",
  "HEE",
  "FDT",
  "DIO",
  "LKK",
  "HET",
  "NSR",
  "BSY",
  "HYA",
  "OUP",
  "MDD",
  "PGX",
  "OSH",
  "DSP",
  "KYZ",
  "KHE",
  "CCL",
  "MSD",
  "HKY",
  "NRY",
  "JAQ",
  "LSE",
  "JBD",
  "CBI",
  "MOF",
  "EMM",
  "JJS",
  "BYL",
  "IXL",
  "OBP",
  "QLT",
  "LIF",
  "DWZ",
  "PBI",
  "OBO",
  "JCY",
  "ING",
  "IFB",
  "HQC",
  "GCB",
  "DXX",
  "LKD",
  "HBG",
  "RFZ",
  "FWH",
  "HRS",
  "MQU",
  "OCX",
  "OBK",
  "NWI",
  "OFP",
  "KLK",
  "LRJ",
  "LJB",
  "REK",
  "GYC",
  "INT",
  "MTJ",
  "RCA",
  "LDF",
  "ECO",
  "OMT",
  "MQN",
  "CCN",
  "EKS",
  "JWY",
  "KPS--",
  "JLH",
  "OJJ",
  "DTM",
  "LKO",
  "NWR",
  "BRZ",
  "EBG",
  "QBD",
  "MKT",
  "EJL",
  "NFK",
  "GAD",
  "DTW",
  "PNO",
  "LHO",
  "GFC",
  "CCQ",
  "JAX",
  "LBK",
  "HGY",
  "NPJ",
  "GCP",
  "DWE",
  "MCH",
  "DWS",
  "LXS",
  "FRL",
  "QKR",
  "JHY",
  "IOQ",
  "NZT",
  "PEW",
  "FNG",
  "JDY",
  "NEG",
  "BWF",
  "OMN",
  "ETD",
  "NSU",
  "NFN",
  "FMS",
  "CAX",
  "PQD",
  "mkj",
  "LRM",
  "BTC",
  "MWI",
  "FEM",
  "FBK",
  "HJN",
  "LHJ",
  ":",
  "CFF",
  "MVH",
  "NJL",
  "LQE",
  "JDX",
  "HCH",
  "MFT",
  "PGW",
  "ISH",
  "QRF",
  "LCM",
  "IKX",
  "FHQ",
  "KSD",
  "QEI",
  "MBD",
  "KSB",
  "OWB",
  "IPY",
  "PLF",
  "MSX",
  "QKY",
  "FHA",
  "NVY",
  "KGC",
  "FNY",
  "HJM",
  "NTR",
  "HGD",
  "JEB",
  "DBI",
  "OSN",
  "OUW",
  "OSR",
  "LIQ",
  "DYF",
  "PZG",
  "FFX",
  "JAI",
  "JJG",
  "FFL",
  "BSO",
  "CEK",
  "FHN",
  "OES",
  "FLG",
  "GWG",
  "NIE",
  "FDG",
  "NQX",
  "NCP",
  "IFP",
  "GNW",
  "FRK",
  "PHV",
  "KMA",
  "NXB",
  "MSE",
  "IXY",
  "LZN",
  "MBP",
  "HTW",
  "KIM",
  "JDQ",
  "PQZ",
  "BYD",
  "GKF",
  "JAK",
  "MMX",
  "DQX",
  "FMJ",
  "OJU",
  "LWH",
  "JSC",
  "PBZ",
  "OSI",
  "LDP",
  "PLS",
  "PIO",
  "BGK",
  "ELC",
  "MWA",
  "LNH",
  "OYC",
  "MQS",
  "NKC",
  "KRI",
  "KXJ",
  "LTT",
  "FTR",
  "ELZ",
  "MVR",
  "LPL",
  "RDZ",
  "FTM",
  "MVV",
  "BZR",
  "QFJ",
  "MNJ",
  "DSQ",
  "ISL",
  "GKP",
  "BYJ",
  "KPY",
  "OIP",
  "PGY",
  "HLF",
  "OOY",
  "EPT",
  "KRR",
  "JFS",
  "LPS",
  "HPS",
  "KZI",
  "PEN",
  "NFF",
  "LRZ",
  "DRO",
  "OGS",
  "JJY",
  "GEN",
  "KZF",
  "GYB",
  "HJL",
  "OWD",
  "MLD",
  "NBW",
  "OCL",
  "FSP",
  "GCK",
  "JIY",
  "MVC",
  "CDP",
  "MAX",
  "MYM",
  "FQH",
  "MSV",
  "EID",
  "JJE",
  "FPL",
  "MQH",
  "HGP",
  "GBN",
  "NQL",
  "GKZ",
  "MMS",
  "JWH",
  "LWJ",
  "KNQ",
  "MID",
  "EKB",
  "KHG",
  "PLP",
  "JOY",
  "LLT",
  "DHC",
  "MDZ",
  "NIU",
  "GMW",
  "DRX",
  "DWF",
  "GBQ",
  "BWN",
  "MGQ",
  "DER",
  "MQB",
  "CKH",
  "jjy",
  "MGO",
  "OYT",
  "JBS",
  "NUJ",
  "LGI",
  "CFL",
  "MOD",
  "FQZ",
  "DDR",
  "GYK",
  "MGM",
  "ION",
  "KKH",
  "FHI",
  "OII",
  "PTJ",
  "NJK",
  "HNM",
  "DHN",
  "GCD",
  "BXH",
  "GNX",
  "PZP",
  "POE",
  "ITG",
  "HPT",
  "HMY",
  "CIW",
  "LYC",
  "LRK",
  "LCP",
  "GQX",
  "HEM",
  "K--DD",
  "GDZ",
  "NAA",
  "KNG",
  "KYG",
  "HTJ",
  "GKE",
  "CAZ",
  "KMN",
  "GXQ",
  "MMP",
  "GBK",
  "CPK",
  "LLB",
  "PGI",
  "KXH",
  "FCW",
  "HDW",
  "PFF",
  "MRZ",
  "JDB",
  "JZH",
  "MGU",
  "KXE",
  "JOH",
  "JTW",
  "LRC",
  "LMO",
  "DKA",
  "BTA",
  "OHW",
  "ONN",
  "EBW",
  "KWY",
  "IDY",
  "CBH",
  "NHB",
  "LQM",
  "JTN",
  "OVD",
  "KPS",
  "NXQ",
  "MYT",
  "PZE",
  "DSK",
  "MPN--",
  "FST",
  "MAO",
  "NKQ",
  "QOF",
  "LAN",
  "GAW",
  "NAB",
  "FDI",
  "FWF",
  "FSY",
  "MNB",
  "OWL",
  "CFP",
  "MUU",
  "ILQ",
  "ODP",
  "GZI",
  "PIC",
  "LHI",
  "MOT",
  "LRF",
  "GAZ",
  "FQA",
  "OTL",
  "MOZ",
  "MXK",
  "NQD",
  "FFK",
  "LRN",
  "OGX",
  "OSP",
  "HDA",
  "HXX",
  "KIR",
  "NWO",
  "JCC",
  "DGW",
  "FNM",
  "OAE",
  "JKD",
  "90LMA",
  "MRW",
  "OAB",
  "ODI",
  "CKD",
  "HJK",
  "JQP",
  "HAM",
  "KNY",
  "CHN",
  "LHL",
  "GAB--",
  "EZN",
  "GAA",
  "PAW",
  "HXP",
  "OTN",
  "NXX",
  "LZB",
  "LYL",
  "KRQ",
  "ISD",
  "ETP",
  "PAD",
  "NOM",
  "RBY",
  "GXP",
  "LLH",
  "KNW",
  "ORZ",
  "LKQ",
  "IRO",
  "DXY",
  "GXZ",
  "HJI",
  "NAE",
  "ITY",
  "ITW",
  "EBC",
  "LRS",
  "OFF",
  "NBH",
  "HXJ",
  "FLS",
  "PQP",
  "FZM",
  "JNB",
  "MGB",
  "FKG",
  "-JTC",
  "NFO",
  "NZA",
  "OAS",
  "MDM",
  "DTE",
  "JSK",
  "JOX",
  "DEG",
  "HQZ",
  "FJL",
  "EWO",
  "OHS",
  "FIN",
  "HDQ",
  "LWP",
  "ELW",
  "HWT",
  "IKT",
  "OFT",
  "GXL",
  "HLM",
  "DXF",
  "IKK",
  "OMI",
  "MWJ",
  "IWB",
  "NSA",
  "OAR",
  "MUD",
  "KDI",
  "OHV",
  "JFT",
  "KBJ",
  "OMO",
  "HAX",
  "NPF",
  "FCP",
  "F",
  "NHX",
  "JOR",
  "FCK",
  "JCW",
  "KKO",
  "FPP",
  "LKF",
  "GAT",
  "OFQ",
  "FRF",
  "FSG",
  "LKH",
  "JNM",
  "GKJ",
  "PLL",
  "JPK",
  "MHX",
  "PFV",
  "POL",
  "JHS",
  "BXN",
  "-IYE",
  "FSM",
  "QBH",
  "PBQ",
  "KGN",
  "OAW",
  "OHD",
  "IYB",
  "RCG",
  "FKX",
  "DFC",
  "NCD",
  "MGX",
  "PKL",
  "FWN",
  "IMP",
  "HYB",
  "GKT",
  "GNC",
  "ONA",
  "MPK",
  "JSI",
  "GHM",
  "LHI--",
  "QES",
  "GAX",
  "QEW",
  "EXQ",
  "MAA",
  "FFT",
  "LZU",
  "IXA",
  "OGJ",
  "MLN",
  "MBF",
  "JJN",
  "LPD",
  "NDF",
  "KLB",
  "MAQ",
  "OCJ",
  "IQI",
  "BYW",
  "LPN",
  "REA",
  "NBE",
  "DXS",
  "EJJ",
  "MVN",
  "FKX--",
  "NON",
  "CJY",
  "GES",
  "CGR",
  "NHA",
  "OZE",
  "PFS",
  "FPA",
  "IZO--",
  "DJZ",
  "DSF",
  "RGW",
  "LPP",
  "NKX",
  "HRX",
  "PNY",
  "POS",
  "HMT",
  "JAA",
  "GZJ",
  "LFK",
  "JHJ",
  "KHP",
  "DIC--",
  "OTT",
  "KLA",
  "GQO",
  "MUA",
  "CDN",
  "LIP",
  "IWJ",
  "EZD",
  "LOE",
  "NQO",
  "JOZ",
  "EXE",
  "GER",
  "MOL",
  "NAY",
  "PWL",
  "MLT",
  "LWK",
  "IWX",
  "EXB",
  "LOS",
  "PQF",
  "QMT",
  "PWY",
  "--",
  "JSY",
  "OUJ",
  "OUG",
  "OIY",
  "JOQ",
  "DFH",
  "NQZ",
  "NPM",
  "LYW",
  "KIS",
  "JHI",
  "OYS",
  "LSM",
  "OEX",
  "HKI",
  "PMT",
  "IKZ",
  "OFD",
  "PYX",
  "PQQ",
  "REO",
  "KAK",
  "MVM",
  "EOX",
  "OEU",
  "GQH",
  "GHH",
  "PEV",
  "M",
  "OUB",
  "HXZ",
  "74DWF",
  "MYL",
  "FCL",
  "FJS",
  "CIN",
  "FLD",
  "HTS",
  "EJB",
  "gbx",
  "HTT",
  "OYE",
  "GAR",
  "NWD",
  "LAS",
  "IBM",
  "FZZ",
  "JEA",
  "FCG",
  "OWO",
  "HCF",
  "HGS",
  "MDT",
  "LBH",
  "CHP",
  "KGT",
  "PEP",
  "LMC",
  "NGG",
  "CIX",
  "PVY",
  "INW",
  "GIF",
  "HSX",
  "KDC",
  "ERL",
  "PRR",
  "PGQ",
  "LJC",
  "JEY",
  "KKX",
  "INM",
  "KAM",
  "LYR",
  "MIX",
  "GSG",
  "HXI",
  "FMG",
  "LZO",
  "CAL",
  "NTI",
  "KFK",
  "PSZ",
  "JXM",
  "OOK",
  "DSY",
  "JPD",
  "ESW",
  "JJI",
  "HGI",
  "DXG",
  "MSQ",
  "KZJ",
  "FXG",
  "IBJ",
  "DZI",
  "ISM",
  "NAC",
  "MDK",
  "NLQ",
  "HZJ",
  "QJR",
  "LBR",
  "MJG",
  "IZF",
  "DGC",
  "RDW",
  "MMH",
  "FSE",
  "MTD",
  "JTR",
  "DAP",
  "OEI",
  "LRP",
  "GZT",
  "LFH",
  "FGS",
  "JGS",
  "GDJ",
  "GCQ",
  "FER",
  "PDR",
  "BYX",
  "NPQ",
  "OJG",
  "JFY",
  "KQT",
  "KGY",
  "OOG",
  "HMP",
  "NES",
  "OAF",
  "NCF",
  "FGE",
  "NUC",
  "ITX",
  "MHY",
  "JTZ",
  "EXS",
  "GCY",
  "HNO",
  "HSD",
  "HWD",
  "PTM",
  "KSG",
  "NYI",
  "HNN",
  "CAE",
  "IMK",
  "J",
  "EXF",
  "BZE",
  "CGZ",
  "CGA",
  "KWT",
  "HWA",
  "HCI",
  "LXG",
  "NFB",
  "EYN",
  "MQV",
  "DKJ",
  "OTU",
  "LGB",
  "FJP",
  "ECI",
  "IDT",
  "HOZ",
  "LPO",
  "IWZ",
  "O-FY",
  "DSI",
  "BZO",
  "MPB",
  "KDM",
  "HMR",
  "FAS",
  "MLS",
  "PRB",
  "DXZ",
  "H--NN",
  "JDS",
  "OGG",
  "JRB",
  "JKA",
  "GCJ",
  "NHN",
  "LDH",
  "PKD",
  "OYK",
  "PVZ",
  "CFJ",
  "IZJ",
  "N/a",
  "GGT",
  "GHC",
  "LPH",
  "CZW",
  "DTN",
  "KWA",
  "LYB",
  "OQU",
  "CIB",
  "DCF",
  "GWO",
  "CBF",
  "NML",
  "OVE",
  "JIF",
  "LSR",
  "HIH",
  "JPI",
  "JFL",
  "PXP",
  "OQO",
  "GWF",
  "LTK--",
  "OLW",
  "MKI",
  "FPX",
  "GXY",
  "NZE",
  "PEJ",
  "PMC",
  "MZA",
  "ITH",
  "DTY",
  "HAW",
  "FLE",
  "MQL",
  "JRM",
  "OFG",
  "GBR",
  "KKX--",
  "GDM",
  "DSZ",
  "ODW",
  "NPL",
  "DRG",
  "MHZ",
  "hpz",
  "MAE",
  "MOV",
  "JDH",
  "GZB",
  "DBL",
  "LZG",
  "GMJ",
  "MGR",
  "NHG",
  "DIP",
  "EBL",
  "BZA",
  "JSS",
  "NIK",
  "JGE",
  "PQK",
  "IMF",
  "GSM",
  "NTG",
  "LRD",
  "FIK",
  "NXA",
  "BTQ",
  "EZW",
  "OJN",
  "CEM",
  "GAL",
  "OKC",
  "JWM",
  "NQI",
  "FMH",
  "DRS",
  "GBO",
  "MYA",
  "MNM",
  "BSP",
  "OEV",
  "GGW",
  "GAA41",
  "HBA",
  "HAB",
  "MCW",
  "MUQ",
  "LPG",
  "MRV",
  "INN",
  "MMI",
  "CDO",
  "EME",
  "QDS",
  "JGJ",
  "KPR--",
  "LLM",
  "EMX",
  "NZG",
  "KOO",
  "KXF",
  "ISY",
  "KIF",
  "LFX",
  "GFG",
  "KXT",
  "FQL",
  "BYH",
  "KOD",
  "KMC",
  "LYQ",
  "GBM",
  "KPT",
  "LER",
  "NLW",
  "JMT",
  "LWR",
  "JOP",
  "PMP",
  "JMM",
  "KWD",
  "NGT",
  "KST",
  "KTN",
  "ISW",
  "DHA",
  "DTB",
  "KCN",
  "CAI",
  "LFD",
  "NOU",
  "MTG",
  "KHO",
  "FWZ",
  "LBB",
  "KIC",
  "LLL",
  "NHL",
  "JCB",
  "HHI",
  "GDT",
  "BYE",
  "JCT",
  "DZD",
  "NHJ",
  "HXY",
  "NPK",
  "DKJ--",
  "OJK",
  "DRQ",
  "ILH",
  "DKZ",
  "JCM",
  "GOY",
  "BZN",
  "JPF",
  "IXI",
  "LGZ",
  "muj",
  "CFR",
  "PRD",
  "KDJ",
  "CKG",
  "MOM",
  "KDH",
  "LIX",
  "FDX",
  "EOB",
  "HDX",
  "U--19",
  "LZD",
  "HSZ",
  "OLC",
  "KQJ",
  "EIE",
  "QAN",
  "GEX",
  "GXR",
  "FEN",
  "NWV",
  "FCX",
  "DWA",
  "KDD",
  "DKB",
  "QRA",
  "EFN",
  "IXJ",
  "OWQ",
  "NWX",
  "KRB",
  "PEH",
  "IKN",
  "LBJ",
  "KYW",
  "HWR",
  "DTS",
  "NEP--",
  "IYH",
  "OOI",
  "MPH",
  "QHE",
  "MPL",
  "DZC",
  "NMF",
  "JSM",
  "LOB",
  "OCZ",
  "OGL",
  "DQK",
  "GBT",
  "HTO",
  "KFZ",
  "GKH",
  "PXO",
  "JDW",
  "GSN",
  "DYG",
  "OJR",
  "LJX",
  "MXM",
  "LFM",
  "FRJ",
  "KZA",
  "PGH",
  "LNQ",
  "FCH",
  "NSF",
  "HWX",
  "HTN",
  "NQA",
  "OEQ",
  "GIO",
  "HBX",
  "JD-01",
  "PJB",
  "GTQ",
  "GZL",
  "PHX",
  "KQG",
  "FBO",
  "FCS",
  "CEN",
  "KOA",
  "GWJ",
  "CEC--",
  "OIG",
  "BSJ",
  "PHO",
  "FHW",
  "DZE",
  "DTL",
  "NAD",
  "REU",
  "GNP",
  "BBT",
  "DQO",
  "CGF",
  "LRG",
  "FTN",
  "CAC",
  "HFM",
  "ILY",
  "HWN",
  "GEL",
  "MRD",
  "MRM",
  "EAP",
  "-KPS",
  "JLA",
  "LZH",
  "DRN",
  "LCH",
  "DDC",
  "LPA",
  "FWB",
  "LQR",
  "NOT",
  "NOX",
  "DTR",
  "LYY",
  "DOF",
  "NDN",
  "PNJ",
  "MAI",
  "ILK",
  "GFF",
  "G-IO",
  "MUZ",
  "JRJ",
  "HCA",
  "OFM",
  "CCH",
  "RHP",
  "GNA",
  "JLS",
  "QHJ",
  "OFA",
  "JCA",
  "LEI",
  "NDW",
  "OBJ",
  "NOV",
  "KQC",
  "HQY",
  "MLV",
  "KZP",
  "JIL",
  "ECN",
  "PSG",
  "LRW",
  "JMO",
  "FAJ",
  "FNK",
  "KPF",
  "LGQ",
  "JIX",
  "91",
  "LOF",
  "HAP",
  "QAU",
  "KSR",
  "KDT",
  "QLG",
  "JOS",
  "GWE",
  "LCO",
  "DOP",
  "EYZ",
  "LWQ",
  "KZE",
  "FFO",
  "MMZ",
  "MYN",
  "DWM",
  "NEP",
  "KCT",
  "NEC",
  "HTX",
  "PMX",
  "MJO",
  "LFZ",
  "PNH",
  "LPM",
  "EHK",
  "FFZ",
  "FAQ",
  "DQR",
  "OHM",
  "NYU",
  "KTI",
  "LQQ",
  "PEZ",
  "EBR",
  "DPT",
  "MQG",
  "HFX",
  "FGM",
  "JZG",
  "KCY",
  "FAT",
  "JZQ",
  "CEO",
  "OPU",
  "OQY",
  "DZN",
  "PAX",
  "JIH",
  "KJA",
  "KRK",
  "OFX",
  "DJG",
  "LRT",
  "PXI",
  "KGX",
  "NKG",
  "BSZ",
  "MSH",
  "LFB",
  "LDD",
  "MVK",
  "FZT",
  "KOG",
  "KNF",
  "PFT",
  "JDZ",
  "74GBK",
  "NDG",
  "FIP",
  "GDI",
  "DTQ",
  "EAM",
  "MXG",
  "DAQ",
  "GDO",
  "QDI",
  "FJI",
  "NZJ",
  "OTR",
  "NDH",
  "KNN",
  "BYY",
  "IZH",
  "GSY",
  "HNJ",
  "QLU",
  "CAW",
  "CFN",
  "JQA",
  "LIO",
  "MOI",
  "FME",
];

export const columnDefs = [
  {
    field: "recalling_firm",
    headerName: "Recalling Firm",
    hide: false,
    lockVisible: true,
    filter: "agTextColumnFilter",
    toolPanelClass: "tp-hideaction",
    sortable: true,
  },
  {
    field: "recall_status",
    headerName: "Recall Status",
    suppressMenu: false,
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["","Open, Classified", "Completed", "Terminated"],
      excelMode: 'windows',
    },
    sortable: true,
  },
  {
    field: "classification",
    headerName: "Recall Classification",
    suppressMenu: false,
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["","Class I", "Class II", "Class III"],
      excelMode: 'windows',
    },
    sortable: true,
  },
  {
    field: "recall_type",
    headerName: "Recall Type",
    suppressMenu: false,
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["","Removal", "Correction"],
      excelMode: 'windows',
    },
    sortable: true,
  },
  {
    field: "event_date_initiated",
    headerName: "Event Date Initiated",
    suppressMenu: false,
    filter: "agDateColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
      suppressAndOrCondition: true,
      browserDatePicker: true,
      minValidYear: 2010,
      maxValidYear: 2030,
      filterOptions: [
        "equals",
        "notEqual",
        "lessThan",
        "greaterThan",
        "inRange",
        "blank",
        "notBlank",
        "empty",
      ],
      defaultOption: "equals",
      comparator: DateComparator,
    },
    sort: "desc",
    cellRenderer: (params) => {
      if (
        params.value == null ||
        params.value == undefined ||
        params.value == ""
      )
        return null;

      return moment(params.value).format("MM/DD/YYYY");
    },
  },
  {
    field: "event_date_posted",
    headerName: "Event Date Posted",
    suppressMenu: false,
    hide: true,
    filter: "agDateColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
      suppressAndOrCondition: true,
      browserDatePicker: true,
      minValidYear: 2010,
      maxValidYear: 2030,
      filterOptions: [
        "equals",
        "notEqual",
        "lessThan",
        "greaterThan",
        "inRange",
        "blank",
        "notBlank",
        "empty",
      ],
      defaultOption: "equals",
      comparator: DateComparator,
    },
    cellRenderer: (params) => {
      if (
        params.value == null ||
        params.value == undefined ||
        params.value == ""
      )
        return null;

      return moment(params.value).format("MM/DD/YYYY");
    },
  },
  {
    field: "event_date_terminated",
    headerName: "Event Date Terminated",
    suppressMenu: false,
    filter: "agDateColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
      suppressAndOrCondition: true,
      browserDatePicker: true,
      minValidYear: 2010,
      maxValidYear: 2030,
      filterOptions: [
        "equals",
        "notEqual",
        "lessThan",
        "greaterThan",
        "inRange",
        "blank",
        "notBlank",
        "empty",
      ],
      defaultOption: "equals",
      comparator: DateComparator,
    },
    cellRenderer: (params) => {
      if (
        params.value == null ||
        params.value == undefined ||
        params.value == ""
      )
        return null;

      return moment(params.value).format("MM/DD/YYYY");
    },
  },
  {
    field: "product_code",
    headerName: "Product Code",
    suppressMenu: false,
    filter: "agSetColumnFilter",
    filterParams: {
      values: product_code,
      excelMode: 'windows',
    },
  },
  {
    field: "product_description",
    headerName: "Product Description",
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "event_date_created",
    headerName: "Event Date Created",
    hide: true,
    suppressMenu: false,
    filter: "agDateColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
      suppressAndOrCondition: true,
      browserDatePicker: true,
      minValidYear: 2010,
      maxValidYear: 2030,
      filterOptions: [
        "equals",
        "notEqual",
        "lessThan",
        "greaterThan",
        "inRange",
        "blank",
        "notBlank",
        "empty",
      ],
      defaultOption: "equals",
      comparator: DateComparator,
    },
    cellRenderer: (params) => {
      if (
        params.value == null ||
        params.value == undefined ||
        params.value == ""
      )
        return null;

      return moment(params.value).format("MM/DD/YYYY");
    },
  },
  {
    field: "res_event_number",
    headerName: "Res Event Number",
    hide: true,
    suppressMenu: false,
    filter: "agNumberColumnFilter",
  },
  {
    field: "address_1",
    headerName: "Address 1",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
  },
  {
    field: "address_2",
    headerName: "Address 2",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "city",
    headerName: "City",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "state",
    headerName: "State",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "postal_code",
    headerName: "Postal Code",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "additional_info_contact",
    headerName: "Additional Info Contact",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "root_cause_description",
    headerName: "Root Cause Description",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "reason_for_recall",
    headerName: "Reason For Recall",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "action",
    headerName: "Action",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "product_quantity",
    headerName: "Product Quantity",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "distribution_pattern",
    headerName: "Distribution Pattern",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "product_res_number",
    headerName: "Product Res Number",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "firm_fei_number",
    headerName: "Firm FEI Number",
    hide: true,
    suppressMenu: false,
    enablePivot: true,
    pivot: true,
    flex: null,
    filter: "agTextColumnFilter",
  },
  {
    field: "cfres_id",
    headerName: "Cfres Id",
    hide: true,
    suppressMenu: false,
    enablePivot: true,
    pivot: true,
    flex: null,
    filter: "agTextColumnFilter",
  },

  {
    field: "k_numbers",
    headerName: "K Numbers",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
  },
  {
    field: "recall_link",
    headerName: "Recall Link",
    hide: true,
    suppressMenu: false,
    filter: "agTextColumnFilter",
    cellRenderer: (params) => {
      return `<a href="${params.value}" target="_blank">${params.value}</a>`;
    },
  },
  {
    field: "openfda.device_class",
    headerName: "openFDA - Device Class",
    suppressMenu: false,
    hide: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "openfda.device_name",
    headerName: "openFDA - Device Name",
    suppressMenu: false,
    hide: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "openfda.fei_number",
    headerName: "openFDA - FEI Number",
    suppressMenu: false,
    hide: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "openfda.k_number",
    headerName: "openFDA - K Number",
    suppressMenu: false,
    hide: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "openfda.medical_specialty_description",
    headerName: "openFDA - Medical Specialty Description",
    suppressMenu: false,
    hide: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "openfda.registration_number",
    headerName: "openFDA - Registration Number",
    suppressMenu: false,
    menuTabs: ["generalMenuTab", "columnsMenuTab", "filterMenuTab"],
    hide: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "openfda.regulation_number",
    headerName: "openFDA - Regulation Number",
    suppressMenu: false,
    menuTabs: ["generalMenuTab", "columnsMenuTab", "filterMenuTab"],
    hide: true,
    filter: "agTextColumnFilter",
  },
];
