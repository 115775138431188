<template>
  <div>

    <b-container class="bv-example-row" v-for="key in Object.keys(recallData)" v-bind:key="key">
      <b-row v-if="key === `udi_list`">
        <h5> Udi List</h5>
        <b-table :items="recallData.udi_list" :fields="udiFieldHeader"> </b-table>
      </b-row>
      <b-row v-else-if="key === `openfda`">
        <h5> openFDA</h5>
        <b-table :items="[recallData.openfda]" :fields="['k_number', 'registration_number',
          'fei_number', 'device_name', 'medical_specialty_description', 'regulation_number', 'device_class']"> </b-table>
      </b-row>
      <b-row v-else>
        <b-col cols="4">
          <h5> {{ key }} </h5>
        </b-col>
        <b-col v-if="key ==='recall_link'"cols="8"><a :href="recallData[key]" target="_blank">{{recallData[key]}}</a></b-col>
        <b-col v-else cols="8">{{ recallData[key] }}</b-col>
      </b-row>
    </b-container>

  </div>
</template>
  
<script >
export default {
  props: ["recallData"],
  data() {
    return {
      udiFieldHeader: [{
        key: "udi",
        label: "UDI"
      },
      {
        key: "normalized_udi",
        label: "Normalized UDI",
      },
      {
        key: "is_valid",
        label: "Is Valid"
      },
      {
        key: "all_lots",
        label: "All Lots"
      },
      {
        key: "lots",
        label: "Lots"
      },
      {
        key:"is_kit",
        label: "Is Kit"
      },
      {
        key:"is_implantable",
        label: "Is Implantable"
      }
      ]
    }
  }
}
</script>
   
<style lang="scss" scoped></style>